import { WriteContractReturnType } from '@wagmi/core';
import { AbiConstructor } from 'abitype';
import {
  AVS_CREATOR_ADDR,
  AVS_DIRECTORY_ADDR,
  PROD_AGGREGATOR_ADDR,
  REWARDS_COORDINATOR_ADDR,
  STG_AGGREGATOR_ADDR,
} from 'constants/addresses';
import { deferredArgNames } from 'constants/customAvs';
import AvsCreatorAbi from 'contracts/AvsCreatorAbi';
import BridgeTemplate from 'contracts/templates/BridgeTemplate';
import CoprocessorTemplate from 'contracts/templates/CoprocessorTemplate';
import ECDSAHelloWorldTemplate from 'contracts/templates/ECDSAHelloWorldTemplate';
import GenericTemplate from 'contracts/templates/GenericTemplate';
import MachTemplate from 'contracts/templates/MachTemplate';
import { useEffect, useMemo } from 'react';
import { useNewAvsStore } from 'stores';
import { AVS_QUORUMS, AVS_TYPES, strategiesByQuorums } from 'types/avs';
import { RollupType } from 'types/protoc-gen/rollup';
import {
  Abi,
  AbiFunction,
  Address,
  encodeAbiParameters,
  encodeFunctionData,
  Hash,
  parseAbiParameters,
  parseEther,
} from 'viem';
import { useAccount, useReadContract } from 'wagmi';

import { useWriteTx } from './useWriteTx';

// Define parameters for operator set and strategies
const minimumStake = 0n;

const avsTypeTemplateMap = {
  [AVS_TYPES.GENERIC]: GenericTemplate,
  [AVS_TYPES.BRIDGE]: BridgeTemplate,
  [AVS_TYPES.COPROCESSOR]: CoprocessorTemplate,
  [AVS_TYPES.MACH]: MachTemplate,
  [AVS_TYPES.ECDSA_HELLO_WORLD]: ECDSAHelloWorldTemplate,
};

interface UseAvsCreatorArgs {
  // onError?: (error: WriteContractErrorType | WaitForTransactionReceiptErrorType) => void;
  onCreationInitialized?: (txReceipt: WriteContractReturnType) => void;
  onCreationFinalized?: (txReceipt: WriteContractReturnType) => void;
  avsQuorums: AVS_QUORUMS[];
  avsType: AVS_TYPES;
  chains?: {
    chainId: { value: number };
    rollupType: { value: RollupType };
  }[];
  thresholdWeight?: bigint;
  compiledOutput?: {
    abi: Abi;
    bytecode: string;
    initArgs: any[];
    constructorArgs: any[];
  };
}

export const useAvsCreator = (props: UseAvsCreatorArgs) => {
  const {
    avsQuorums,
    avsType,
    chains,
    compiledOutput,
    onCreationFinalized,
    onCreationInitialized,
    thresholdWeight,
  } = props;

  const isProd = window.location.href.includes('wizard.altlayer.io');

  const {
    activateAvsRes,
    createECDSATxHash,
    finalizeTxHash,
    initTxHash,
    setCreateECDSATxHash,
    setFinalizeTxHash,
    setInitTxHash,
  } = useNewAvsStore();

  const { minimumStakeForQuorum, operatorSetParams, strategyParams } = useMemo(() => {
    const numQuorums = avsQuorums?.length || 0;
    const BPS = 10_000n; // hardcoded in ECDSAStakeRegistryStorage

    return {
      // Strategy parameters per quorum, mapping each strategy to a weight. Total must === BPS (10_000)
      strategyParams: avsQuorums?.map(quorum =>
        strategiesByQuorums[quorum]
          ?.sort((a, b) => (a?.toLowerCase() > b?.toLowerCase() ? 1 : -1)) // Reverts if sorted by checksummed address
          ?.map((token, index) => ({
            strategy: token,
            multiplier:
              avsType === AVS_TYPES.ECDSA_HELLO_WORLD || avsType === AVS_TYPES.CUSTOM_ECDSA
                ? index === 0
                  ? BPS / BigInt(strategiesByQuorums[quorum]?.length) +
                    (BPS % BigInt(strategiesByQuorums[quorum]?.length))
                  : BPS / BigInt(strategiesByQuorums[quorum]?.length)
                : parseEther('1'),
          })),
      ),
      // Define operator set parameters for each quorum
      operatorSetParams: new Array(numQuorums).fill({
        maxOperatorCount: 50,
        kickBIPsOfOperatorStake: 11000,
        kickBIPsOfTotalStake: 1001,
      }),
      // Prepare minimum stakes array
      minimumStakeForQuorum: Array(numQuorums).fill(minimumStake),
    };
  }, [avsQuorums, avsType]);

  useEffect(() => {
    console.debug('strategyParams changed to: ', strategyParams);
    console.debug('operatorSetParams changed to: ', operatorSetParams);
    console.debug('minimumStakeForQuorum changed to: ', minimumStakeForQuorum);
  }, [strategyParams, operatorSetParams, minimumStakeForQuorum]);

  const { address } = useAccount();

  const { isPending: isInitPending, write: initializeCreation } = useWriteTx({
    contractAbi: AvsCreatorAbi,
    contractAddress: AVS_CREATOR_ADDR,
    txKey: `init_${address}`,
    functionName: 'initializeCreation',
    functionArgs: [
      address,
      address,
      address,
      operatorSetParams,
      minimumStakeForQuorum,
      strategyParams,
    ],
    onTxConfirmed: txHash => {
      setInitTxHash(txHash);
      onCreationInitialized?.(txHash);
    },
  });

  const { data: ongoingDeployments } = useReadContract({
    abi: AvsCreatorAbi,
    address: AVS_CREATOR_ADDR,
    functionName: 'ongoingDeployments',
    args: [address as Address],
    query: {
      enabled: Boolean(address && initTxHash),
    },
  });
  const [
    initialOwner,
    _proxyAdmin,
    pauserRegistry,
    _indexRegistryProxy,
    stakeRegistryProxy,
    _apkRegistryProxy,
    registryCoordinatorProxy,
    _serviceManagerProxy,
  ] = ongoingDeployments || [];

  const argNameToAddrMap: Record<string, Address | undefined> | undefined = useMemo(
    () =>
      stakeRegistryProxy || registryCoordinatorProxy || pauserRegistry
        ? {
            pauserRegistry_: pauserRegistry,
            __registryCoordinator: registryCoordinatorProxy,
            __stakeRegistry: stakeRegistryProxy,
          }
        : undefined,
    [pauserRegistry, registryCoordinatorProxy, stakeRegistryProxy],
  );

  const constructorArgsBytecode = useMemo(() => {
    if (avsType === AVS_TYPES.ECDSA_HELLO_WORLD) {
      return '';
    }

    if (avsType === AVS_TYPES.CUSTOM_ECDSA) {
      if (!compiledOutput) {
        return '';
      }

      const constructorArgsAbi = (
        compiledOutput.abi?.find(cur => cur?.type === 'constructor') as AbiConstructor
      )?.inputs;

      const constructorArgsSuffix = constructorArgsAbi?.slice(3);

      if (!constructorArgsSuffix?.length) {
        return '';
      }

      const constructorArgsFinal = compiledOutput.constructorArgs?.map((cur: any, index) =>
        deferredArgNames?.includes(constructorArgsAbi?.[index]?.name || '')
          ? argNameToAddrMap?.[constructorArgsAbi?.[index]?.name || '']
          : cur,
      );

      return encodeAbiParameters(constructorArgsSuffix, constructorArgsFinal?.slice(3));
    }

    if (avsType === AVS_TYPES.CUSTOM_BLS) {
      if (!compiledOutput || !argNameToAddrMap) {
        return '';
      }

      const constructorArgsAbi = (
        compiledOutput.abi?.find(cur => cur?.type === 'constructor') as AbiConstructor
      )?.inputs;

      if (!constructorArgsAbi) return '';

      const constructorArgsFinal = compiledOutput.constructorArgs?.map((cur: any, index) =>
        deferredArgNames?.includes(constructorArgsAbi?.[index]?.name || '')
          ? argNameToAddrMap?.[constructorArgsAbi?.[index]?.name || '']
          : cur,
      );

      console.debug('constructorArgValues final: ', constructorArgsFinal);

      return encodeAbiParameters(constructorArgsAbi, constructorArgsFinal);
    }

    if (!registryCoordinatorProxy || !stakeRegistryProxy) return '';

    if (avsType === AVS_TYPES.MACH || avsType === AVS_TYPES.COPROCESSOR) {
      return encodeAbiParameters(parseAbiParameters(['address', 'address', 'address', 'address']), [
        AVS_DIRECTORY_ADDR,
        REWARDS_COORDINATOR_ADDR,
        registryCoordinatorProxy,
        stakeRegistryProxy,
      ]);
    }

    return encodeAbiParameters(
      parseAbiParameters(['address', 'address', 'address', 'address', 'uint32']),
      [
        AVS_DIRECTORY_ADDR,
        REWARDS_COORDINATOR_ADDR,
        registryCoordinatorProxy,
        stakeRegistryProxy,
        30,
      ],
    );
  }, [argNameToAddrMap, avsType, compiledOutput, registryCoordinatorProxy, stakeRegistryProxy]);

  const encodedFnData = useMemo(() => {
    if (avsType === AVS_TYPES.ECDSA_HELLO_WORLD && !!address) {
      const res = encodeFunctionData({
        abi: avsTypeTemplateMap[avsType].abi,
        functionName: 'initialize',
        args: [address, address, address],
      });

      return res;
    }

    if (avsType === AVS_TYPES.CUSTOM_BLS) {
      if (compiledOutput && argNameToAddrMap) {
        const initArgsAbi = (
          compiledOutput.abi?.find(cur => {
            if ('name' in cur && cur?.name && cur?.type === 'function') {
              return cur?.name === 'initialize';
            }

            return false;
          }) as AbiFunction
        )?.inputs;

        const initArgsFinal = compiledOutput.initArgs?.map((cur: any, index) =>
          deferredArgNames?.includes(initArgsAbi?.[index]?.name || '')
            ? argNameToAddrMap?.[initArgsAbi?.[index]?.name || '']
            : cur,
        );

        console.debug('init args final: ', initArgsFinal);
        const res = encodeFunctionData({
          abi: compiledOutput.abi,
          functionName: 'initialize',
          args: initArgsFinal,
        });

        return res;
      }

      return null;
    }

    if (avsType === AVS_TYPES.CUSTOM_ECDSA) {
      if (compiledOutput) {
        const res = encodeFunctionData({
          abi: compiledOutput.abi,
          functionName: 'initialize',
          args: compiledOutput.initArgs,
        });

        return res;
      }

      return null;
    }

    if (!initialOwner || !pauserRegistry) return null;

    return encodeFunctionData({
      abi: avsTypeTemplateMap[avsType].abi,
      functionName: 'initialize',
      args:
        avsType === AVS_TYPES.COPROCESSOR
          ? [pauserRegistry, 0n, initialOwner, initialOwner, initialOwner]
          : avsType === AVS_TYPES.MACH
          ? [
              pauserRegistry,
              0n,
              initialOwner,
              initialOwner,
              initialOwner,
              initialOwner,
              chains?.map(cur => BigInt(cur.chainId?.value || 0)) || [],
            ]
          : [
              pauserRegistry,
              0n,
              initialOwner,
              initialOwner,
              initialOwner,
              isProd ? PROD_AGGREGATOR_ADDR : STG_AGGREGATOR_ADDR,
              initialOwner,
            ],
    });
  }, [
    avsType,
    address,
    initialOwner,
    pauserRegistry,
    chains,
    isProd,
    compiledOutput,
    argNameToAddrMap,
  ]);

  const { isPending: isFinalizing, write: finalizeCreation } = useWriteTx({
    contractAbi: AvsCreatorAbi,
    contractAddress: AVS_CREATOR_ADDR,
    txKey: `finalize_${address}`,
    functionName: 'finalizeCreation',
    functionArgs: [
      avsType === AVS_TYPES.CUSTOM_BLS || avsType === AVS_TYPES.CUSTOM_ECDSA
        ? compiledOutput?.bytecode
        : avsTypeTemplateMap[avsType].bytecode.object,
      constructorArgsBytecode,
      encodedFnData,
    ],
    onTxConfirmed: txHash => {
      setFinalizeTxHash(txHash);
      onCreationFinalized?.(initTxHash as Hash);
    },
  });

  console.debug(
    'total multiplier: ',
    strategyParams?.[0]?.reduce((sum, cur) => sum + cur?.multiplier || 0n, 0n),
  );

  const { isPending: isCreatingECDSA, write: createECDSA } = useWriteTx({
    contractAbi: AvsCreatorAbi,
    contractAddress: AVS_CREATOR_ADDR,
    txKey: `createECDSA_${address}`,
    functionName: 'createECDSA',
    functionArgs:
      avsType === AVS_TYPES.ECDSA_HELLO_WORLD
        ? [
            address,
            thresholdWeight,
            strategyParams?.[0],
            avsTypeTemplateMap[avsType].bytecode.object,
            '',
            encodedFnData,
          ]
        : avsType === AVS_TYPES.CUSTOM_ECDSA
        ? [
            address,
            thresholdWeight,
            strategyParams?.[0],
            compiledOutput?.bytecode,
            constructorArgsBytecode, // constructorArgsSuffix
            encodedFnData,
          ]
        : undefined,
    onTxConfirmed: txHash => {
      setCreateECDSATxHash(txHash);
      onCreationFinalized?.(txHash);
    },
  });

  const status: 'FINALIZED' | 'READY_FOR_FINALIZE' | 'INITIALIZED' | 'ACTIVATED' | 'IDLE' =
    useMemo(() => {
      if (createECDSATxHash || finalizeTxHash) return 'FINALIZED';
      if (initTxHash && encodedFnData && constructorArgsBytecode) return 'READY_FOR_FINALIZE';
      if (initTxHash) return 'INITIALIZED';
      if (activateAvsRes) return 'ACTIVATED';

      return 'IDLE';
    }, [
      activateAvsRes,
      constructorArgsBytecode,
      createECDSATxHash,
      encodedFnData,
      finalizeTxHash,
      initTxHash,
    ]);

  return useMemo(
    () => ({
      initializeCreation,
      isInitPending,
      initTxHash,
      finalizeTxHash,
      finalizeCreation,
      isFinalizing,
      createECDSA,
      isCreatingECDSA,
      createECDSATxHash,
      status,
    }),
    [
      createECDSA,
      createECDSATxHash,
      finalizeCreation,
      finalizeTxHash,
      initTxHash,
      initializeCreation,
      isCreatingECDSA,
      isFinalizing,
      isInitPending,
      status,
    ],
  );
};
